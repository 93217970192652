body {
    color: rgb(85, 85, 85);
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
    line-height: 160%;
}

.ui.borderless.menu {
    margin-top: 0;
}

.ui.inverted.pointing.menu .active.item:after {
    background-color: white !important;
}

.ui.basic.segment {
    padding-top: 4rem;
}

.ui.basic.segment > .ui.huge.header {
    font-size: 3em;
}

.ui.secondary.segment.footer {
    margin-top:5em;
    border:none;
}

.eleven.wide.column {
    font-size: 18px;
}

.four.wide.column .ui.secondary.segment {
    border: none;
}

footer.ui.segment {
    margin-bottom: 0;
    padding-top: 40px;
    padding-bottom: 40px;
}

/* blockquote styles comes from bootstrap */
blockquote {
    padding: 0.6rem 1rem 0.4rem;
    margin: 0 0 0.8rem;
    border-left: 5px solid #eeeeee;
}

blockquote p {
    line-height: 1.2rem;
    margin-bottom: 0.3rem;
    text-align: start !important;
}

blockquote p:last-child {
    margin-bottom: 0;
}

blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
    margin-bottom: 0.5rem;
    display: block;
    font-size: 80%;
    line-height: 1.42857;
    color: #999;
    padding: 0;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: "— ";
}

blockquote cite:before {
    content: "«";
}

blockquote cite:after {
    content: "»";
}

li {
    line-height: 160%;
}

h3.ui.header {
    margin-top:2.5em;
}

.ui.small.header .sub.header {
    font-size: 0.8em;
}


.ui.medium.image, .ui.medium.images .image, .ui.medium.images img, .ui.medium.images svg {
    width: 375px;
}

.body > * {
    margin-top: 0.2em;
}

pre {
    background-color: #E6E6FA;    
    padding: 10px;
    white-space: pre-wrap;
}

.langH {
    cursor: pointer;
}